import { Injectable, Inject } from "@angular/core";
import { LocalStoreService } from "../../../shared/services/local-store.service";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AuthService } from '../../../shared/services/auth.service';

@Injectable({
  providedIn: "root"
})
export class ItemsPriceService {
  constructor(
    private store: LocalStoreService,
    @Inject('itemsApiUrl') private itemsApiUrl,
    private http: HttpClient,
    private authService: AuthService) { }

  list(skip, take, currentPage, search): Observable<any> {
    let pagingData = {};
    pagingData['skip'] = skip;
    pagingData['take'] = take;
    pagingData['currentPage'] = currentPage;
    pagingData['orderColumn'] = -1;
    pagingData['orderBy'] = 'DESC';

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Basic bW9iaW1ib19kZXY6YTV6eGZ0N1Ah',
        'Content-Type': 'application/json'
      })
    }
    
    return this.http.post(`${this.itemsApiUrl}/${this.authService.firmaGuid}/price-list`, { pagingData, search: search }, httpOptions)
      .pipe(map(res => {
        return res
      }))
  }

  listColumns(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Basic bW9iaW1ib19kZXY6YTV6eGZ0N1Ah',
        'Content-Type': 'application/json'
      })
    }

    return this.http.get(`${this.itemsApiUrl}/${this.authService.firmaGuid}/price-columns`, httpOptions)
      .pipe(map(res => {
        return res
      }))
  }

  insertOrUpdateColumn(column): Observable<any> {
    if(!column.id) {
      column['id'] = 0;
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Basic bW9iaW1ib19kZXY6YTV6eGZ0N1Ah',
        'Content-Type': 'application/json'
      })
    }

    return this.http.post(`${this.itemsApiUrl}/${this.authService.firmaGuid}/price-columns`, column, httpOptions)
      .pipe(map(res => {
        return res
      }))
  }

  delete(columnId): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Basic bW9iaW1ib19kZXY6YTV6eGZ0N1Ah',
        'Content-Type': 'application/json'
      })
    }

    return this.http.delete(`${this.itemsApiUrl}/${this.authService.firmaGuid}/price-columns/${columnId}`, httpOptions)
      .pipe(map(res => {
        return res
      }))
  }

  savePriceList(priceList): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Basic bW9iaW1ib19kZXY6YTV6eGZ0N1Ah',
        'Content-Type': 'application/json'
      })
    }

    return this.http.post(`${this.itemsApiUrl}/${this.authService.firmaGuid}/price-list-kaydet`, priceList, httpOptions)
      .pipe(map(res => {
        return res
      }))
  }
}
